.TitreTarifs{
    text-align: center;
    color:#f5a624;
    margin-top: 5%;
    font-size: 4.5rem;
    
}

.TitreTarifs h2{
    font-size: 3rem;
}

.tblClient{
    text-align: center;
    margin-top: 5%;
    margin-bottom: 5%;
    border: 2px solid orange;
    border-radius: 28px;
    margin-left: 2%;
    margin-right: 2%;
}
.tblClient h4{
    font-size: 1.4rem;
}
.client{
    margin-top: 8%;
  
}


@media (max-width: 640px) {
    .tblClient{
        margin-left: 0%;
        margin-right: 0%;

    }

    .tblClient h4{
        font-size: 0.4rem;
    }

    h1{
        font-size: 2em;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .tblClient{
        margin-left: 0%;
        margin-right: 0%;

    }

    .tblClient h4{
        font-size: 0.4rem;
    }

    h1{
        font-size: 2em;
    }
}