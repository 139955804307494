/* contact */
.colContact {
  margin-top: 4%;
  text-align: center;
  color: #f5a624;
}

/* contact mobile */
.hmobile {
  font-size: 20px;
}

.backgroundContact {
  background-color: #4a4a4a;
}

.logoServices {
  z-index: 12;
  width: 40%;
  padding-left: 5%;
  margin-top: -2%;
  transition: left 1s, transform 1s;
}
