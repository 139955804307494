.FAQ .colButton {
  text-align: right;
  margin-top: 0.5%;
}

.FAQ .colUserMobile {
  display: none;
}

.FAQ .logoPartie1faq {
  text-align: center;
  padding-top: 2%;
  padding-bottom: 5%;
}

.logoPartie1faq img {
  width: 20%;
}

.paraClientFaqh3 a {
  color: #f5a624 !important;
}

.FAQ .backgroundPartie1 {
  background-color: #f5a624;
}

.deuxMin {
  margin-top: 3%;
}

.justifyBenef {
  justify-content: center;
}
.FAQ .paraClientFaq {
  margin-top: 10%;
  margin-bottom: 3%;
  text-align: center;
  color: #f5a624;
}

.orange {
  color: #f5a624;
}
.linkArticle {
  color: black !important;
}

.FAQ .titleFaq {
  justify-content: center;
}
.FAQ .ligne {
  margin-bottom: 0% !important;
}
.FAQ .paraClientFaqh3 {
  margin-bottom: 3%;
  text-align: center;
  color: #f5a624;
}

.FAQ .paraClientTexteFaq {
  text-align: center;
  padding-left: 15%;
  padding-right: 15%;
  font-size: 22px;
}

.FAQ .blocClient img {
  width: 100% !important;
}

.FAQ .blocClientFaq img {
  width: 38%;
  height: 100%;
}

.FAQ .blocClientFaq {
  text-align: center;
  border-style: solid;
  border-radius: 28px;
  border-block-start: none;
  border-width: 1px;
  border-color: #8080802b;
  margin-bottom: 2%;
  margin-right: 2%;
  padding-top: 1%;
}

.FAQ .rowIconeClient {
  text-align: center;
  justify-content: center;
  margin-top: 3%;
  margin-bottom: 5%;
}

.FAQ .paraIcone {
  color: white;
  margin-top: 7%;
}

.FAQ .backgroundClient {
  background-image: url("/images/bandeauClient.png");
  background-size: cover; /* <------ */
  background-repeat: no-repeat;
  background-position: center center;
  padding-bottom: 35%;
}

.FAQ .backgroundServeur {
  background-image: url("/images/bandeauServeur.png");
  background-size: cover; /* <------ */
  background-repeat: no-repeat;
  background-position: center center;
  padding-bottom: 35%;
}
.FAQ .backgroundRestaurateur {
  background-image: url("/images/bandeauRestaurateur.png");
  background-size: cover; /* <------ */
  background-repeat: no-repeat;
  background-position: center center;
  padding-bottom: 35%;
}

.FAQ .rowFAQ p {
  margin-bottom: 0.3%;
  margin-top: -9px !important;
  font-size: 15px !important;
}

.FAQ .rowFAQ {
  justify-content: center;
}

.FAQ .rowFAQ h1 {
  font-size: 18px;
}
.rowFiscalite {
  padding-left: 5%;
}

.sousTitreFaq {
  color: #f5a624;
  font-size: 32px !important;
}

.rowFAQ h3 {
  color: #f5a624;
  font-size: 1.25rem !important;
}

.rowFAQ .chatFAQ {
  background-color: #f5a624 !important ;
}

.rowFAQ button:hover {
  background-color: grey !important;
  border-color: grey;
}

.rowFAQ .butCgu {
  margin-bottom: 2%;
  margin-top: 24%;
}

.rowFAQ .partners {
  width: 88% !important;
}

.rowFAQ .tarif {
  width: 59% !important;
}

.rowFAQ .chat {
  width: 54% !important;
}

.rowFAQ .butSecu {
  margin-top: 30%;
}

.rowFAQ .butPartners {
  margin-top: 29%;
}

.rowFAQ button {
  color: white;
  background-color: #f5a624 !important ;
  border-radius: 28px;
  margin-top: 34%;
  margin-bottom: 2%;
}

.rowButFaq .col {
  text-align: center;
}

.butFaq {
  color: black !important;
  border-color: black !important;
  margin-bottom: 5%;
}
.butInscr {
  background-color: #7200ff;
  color: #f5a624 !important;

  margin-bottom: 5%;
}

.center {
  text-align: center;
}

.rowFAQ .freqQuest {
  font-size: 13px;
  margin-bottom: 6%;
  margin-top: 24%;
}

@media (max-width: 640px) {
  .FAQ .paraClientTexteFaq {
    text-align: center;
    padding-left: 5%;
    padding-right: 5%;
    font-size: 20px;
  }

  .rowFiscalite {
    padding-left: 5%;
  }

  .FAQ .blocClientFaq {
    text-align: center;
    border-style: solid;
    border-radius: 28px;
    border-block-start: none;
    border-width: 1px;
    border-color: #8080802b;
    margin-bottom: 2%;
    margin-right: 0%;
    padding-top: 1%;
  }

  .rowFAQ .freqQuest {
    font-size: 10px;
  }
  .rowFAQ .butCgu {
    font-size: 10px;
  }

  .rowFAQ button {
    font-size: 10px;
  }

  .rowEnd {
    margin-bottom: 10%;
  }
}
