/* general */

body {
  font-family: "Nunito", Semi-bold;
  background-color: #ffffff !important;
}

h1 {
  font-size: 2.5em;
}

.buttonPreInscr {
  background-color: #7200ff !important;
  position: -webkit-sticky;
  font-size: 20px;
  top: 0;

  float: right;
  border-width: 0;
  padding: 9% 4% 3%;
  border-radius: 20px;
  vertical-align: top;
  transform: rotate(90deg);
  -ms-transform: rotate(90deg) !important; /* IE 9 */
  -moz-transform: rotate(90deg) !important; /* Firefox */
  -webkit-transform: rotate(90deg) !important; /* Safari and Chrome */
  -o-transform: rotate(90deg) !important; /* Opera */
}

/* STYLE POPUP LANCEMENT */
.displayFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.promoButton {
  border-radius: 28px;
  background-color: #f5a624 !important;
}

.promoButton:hover {
  background-color: #555 !important;
  color: #f5a624 !important;
  border-color: #f5a624 !important ;
}

.logoPopup {
  width: 20vh;
  height: 10vh;
}
.textePopup {
  font-size: 16px;
  font-weight: bold;
  color: #f5a624 !important;
  text-decoration: none !important;
}
.texteOtherPop {
  font-size: 16px;
  font-weight: bold;
  color: black !important;
  text-decoration: none !important;
}
.colPre {
  position: fixed;
  left: 90px;
  top: 45%;
  z-index: 2000;
}

.flecheInscr {
  margin-left: 0.4rem;
  background-color: #f5a624 !important;
  color: #7200ff;
  border-width: 0;
  vertical-align: top;
  transform: rotate(7deg);
  -ms-transform: rotate(270deg); /* IE 9 */
  -moz-transform: rotate(270deg); /* Firefox */
  -webkit-transform: rotate(270deg); /* Safari and Chrome */
  -o-transform: rotate(270deg); /* Opera */
}

.flecheInscr:hover {
  background-color: transparent;
  border-color: white;
}

.puff-in-center {
  animation: focus-in-expand-fwd 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/*modal*/

.imgModal {
  width: 30%;
  margin-bottom: 3%;
}

.inputModal {
  margin-bottom: 4%;
}

.modal-body {
  text-align: center;
}

.colBenef {
  justify-content: center;
}

.inscriptionModal {
  border-radius: 28px;
  background-color: white;
  color: black !important;
  border-width: 1px;
  border-color: black !important;
  margin-bottom: 3%;
  margin-top: 5%;
}

/* Partie 1 */

.partie1 {
  background-image: url("./fond.png");
  background-size: cover; /* <------ */
  background-repeat: no-repeat;
  background-position: 24% 10%;
}
.colUserMobile {
  display: none;
}

.logoPartie1 {
  text-align: center;
  padding-bottom: 11%;
}

.logoPartie1 img {
  width: 25%;
}

.colButton {
  text-align: right;
  margin-top: 0.5%;
}
.btn {
  background-color: transparent;
  border-color: white;
  border-radius: 28px;
}

.widthImUser {
  width: 30%;
  margin-top: 31px;
}

.btn:hover {
  background-color: transparent;
  border-color: #f5a624;
}

.margin {
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 20px;
}

.trigger {
  border-radius: 28px;
  background-color: #f5a624;
  padding: 2%;
  border-width: 0px;
  color: white;
  font-size: 18px;
  padding-left: 4%;
  padding-right: 4%;
  margin-bottom: 19%;
}
.triggerModal {
  border-radius: 28px;
  background-color: #f5a624;
  padding: 2%;
  border-width: 0px;
  color: white;
  font-size: 12px;
  padding-left: 4%;
  padding-right: 4%;
}
.trigger2 {
  border-radius: 28px;
  background-color: #f5a624;
  padding: 2%;
  border-width: 0px;
  color: white;
  font-size: 18px;
  padding-left: 4%;
  padding-right: 4%;
}
.asso2 {
  margin-bottom: 12% !important;
}

.modalButton {
  border-radius: 28px;
  background-color: #f5a624 !important;
}

.modalButton:hover {
  background-color: #555 !important;
  color: #f5a624 !important;
  border-color: #f5a624 !important ;
}

.CheckModal {
  text-align: right;
  z-index: 20001;
}

.index {
  z-index: 2001;
}
/* partie 2 services titres */

.partie2 {
  padding-top: 20%;
  z-index: 2;
}
.enSavoirPlus {
  border-radius: 28px;
  background-color: white;
  color: black !important;
  border-width: 1px;
  border-color: black;
  margin-bottom: 3%;
}

.partie2Titre {
  text-align: center;
  color: #f5a624;
  margin-top: 3%;
  -webkit-animation: focus-in-expand-fwd 1.2s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: focus-in-expand-fwd 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.partie2SousTitre {
  text-align: center;
  animation: focus-in-expand-fwd 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/*mockups*/
.mockup1 {
  position: absolute;
  text-align: center;
  top: 29%;
  left: -6%;
  z-index: 1;
}
.mockup2 {
  position: absolute;
  text-align: center;
  top: 40%;
  left: 35%;
  z-index: 3;
}

.mockup1 img {
  width: 20%;
}
.mockup2 img {
  width: 35%;
}
/* partie 3 services blocs */

.partie3 {
  padding-top: 10%;
}

.partie3 p {
  padding-left: 17%;
  padding-right: 17%;
  margin-bottom: 0%;
}
.partie3Titre {
  text-align: center;
  color: #f5a624;
}

.clientBloc {
  text-align: center;

  transition: transform 0.4s;
}

.serveurBloc {
  text-align: center;
  transition: transform 0.4s;
}
.serveurBloc:hover {
  transform: scale(1.2);
  transition: transform 0.8s;
}
.serveurBloc:hover h3 {
  transform: scale(1.2);
  transition: transform 0.8s;
  color: #f5a624;
}

.paraSer {
  font-size: 18px;
}

.buttonPreInscr:link,
.buttonPreInscr:visited {
  text-transform: uppercase;
  text-decoration: none;
  padding: 15px 40px;
  display: inline-block;
  border-radius: 100px;
  transition: all 0.2s;
  position: absolute;
}

.buttonPreInscr:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.buttonPreInscr:active {
  transform: translateY(-1px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.buttonPreInscr::after {
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 100px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.4s;
}

.buttonPreInscr:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}

.buttonPreInscr {
  animation: moveInBottom 2s ease-out;
  animation-fill-mode: backwards;
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
    transform: rotate(90deg);
  }
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(20);
    transform: scale(20);
  }
  100% {
    -webkit-transform: scale(20);
    transform: scale(20);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(20);
    transform: scale(20);
  }
  100% {
    -webkit-transform: scale(20);
    transform: scale(20);
  }
}

.colorA {
  color: black;
}
.restaurateurBloc {
  text-align: center;
  transition: transform 0.4s;
}

.restaurateurBloc:hover {
  transform: scale(1.2);
  transition: transform 0.8s;
}
.restaurateurBloc:hover h3 {
  transform: scale(1.2);
  transition: transform 0.8s;
  color: #f5a624;
}

.clientBloc img {
  width: 13%;
  margin-bottom: 6%;
}

.clientBloc:hover {
  transform: scale(1.2);
  transition: transform 0.8s;
}
.clientBloc:hover h3 {
  transform: scale(1.2);
  transition: transform 0.8s;
  color: #f5a624;
}

.serveurBloc img {
  width: 15%;
  margin-bottom: 7%;
}

.restaurateurBloc img {
  width: 14%;
  margin-bottom: 5%;
}

.video {
  width: 100%;
}

.popup-content {
  left: 0px !important;
}

/* partie 4 environnement*/

.partie4Titre {
  text-align: center;
  color: #f5a624;
}

.partie4 img {
  width: 14%;
  margin-top: 10%;
}
.partie4SousTitre {
  text-align: center;
}

/*partie 5 contact */

.partie5 {
  background-color: #f5a624;

  padding-bottom: 3%;
}

.partie5 img {
  width: 26%;
}

.partie5image {
  text-align: center;
  padding-top: 4%;
}

.partie5Contact {
  text-align: center;
  color: #ffffff;
  padding-top: 4%;
}

.adresseContact a {
  text-decoration: underline #c4c4c4;
  color: #c4c4c4 !important;
  font-size: 28px;
}
.treeIMG:hover {
  -webkit-animation: wobble-hor-bottom 0.8s both;
  animation: wobble-hor-bottom 0.8s both;
}

@-webkit-keyframes wobble-hor-bottom {
  0%,
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-30px) rotate(-6deg);
    transform: translateX(-30px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateX(15px) rotate(6deg);
    transform: translateX(15px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(-3.6deg);
    transform: translateX(-15px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateX(9px) rotate(2.4deg);
    transform: translateX(9px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateX(-6px) rotate(-1.2deg);
    transform: translateX(-6px) rotate(-1.2deg);
  }
}
@keyframes wobble-hor-bottom {
  0%,
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-30px) rotate(-6deg);
    transform: translateX(-30px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateX(15px) rotate(6deg);
    transform: translateX(15px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(-3.6deg);
    transform: translateX(-15px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateX(9px) rotate(2.4deg);
    transform: translateX(9px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateX(-6px) rotate(-1.2deg);
    transform: translateX(-6px) rotate(-1.2deg);
  }
}

a {
  color: white !important;
  font-size: 28px;
}

.fab {
  color: #ffffff;
  margin: 12px;
  font-size: 37px;
  margin-right: 10px;
}

.copy {
  text-align: center;
  font-size: 10px;
}

.copy a {
  font-size: 18px;
}
.iconeFab {
  text-align: center;
}

.mockup1mobile {
  display: none;
}

.mockup1Ipad {
  display: none;
}

.mockup2Ipad {
  display: none;
}
.colAsso img {
  width: 50%;
}

.rowAsso {
  margin-top: 4%;
  margin-bottom: 4%;
}
.colAsso {
  text-align: center;
}

@media (max-width: 640px) {
  /* CSS mobile ici */

  /*modal*/
  .buttonPreInscr {
    padding-top: 10%;
    padding-bottom: 2%;
    padding-left: 6%;
    padding-right: 4%;
  }

  .colPre {
    z-index: 1;
    left: 86px;
    top: 45%;
  }

  .cookie {
    z-index: 5000;
  }

  .logoPartie1 {
    padding-bottom: 3% !important;
  }

  .logoPartie1 img {
    margin-top: -9% !important;
    width: 50% !important;
  }

  .colUserMobile {
    display: block;
    text-align: right;
    margin-top: 1%;
  }

  .widthImUserMobile {
    width: 8%;
  }

  .widthImUser {
    display: none;
  }
  .mockup1 {
    display: none;
  }

  .partie2 h1 {
    font-size: 1.8rem;
  }

  .partie2 {
    padding-top: 6%;
  }

  .partie2 h3 {
    font-size: 1.6rem;
  }

  .mockup2 {
    display: none;
  }

  .logoPartie1 img {
    width: 63%;
    margin-top: 20%;
    padding-bottom: 0%;
  }
  .mockup1mobile {
    display: block !important;
    text-align: center;
  }

  .mockup1mobile img {
    width: 68%;
    padding-right: 10%;
  }

  .colButton {
    display: none;
  }

  .clientBloc {
    margin-bottom: 20%;
  }
}

@media (min-width: 576px) and (max-width: 736px) {
  .mockup1 {
    display: none;
  }

  .mockup2 {
    display: none;
  }
  .widthImUser {
    display: none;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .colUserMobile {
    display: block;
    text-align: right;
    margin-top: 1%;
  }

  .widthImUserMobile {
    width: 4%;
  }
  .mockup1 {
    display: none;
  }

  .mockup2 {
    display: none;
  }

  .mockup1Ipad {
    display: block;
    position: absolute;
    top: 13%;

    left: 19%;
  }

  .mockup1Ipad img {
    width: 119%;
  }

  .mockup2Ipad {
    display: block;
    position: absolute;
    top: 15%;
    left: 42%;
  }

  .mockup2Ipad img {
    width: 109%;
  }

  .colButton {
    display: none;
  }
}

/*ipad pro only*/

@media only screen and (min-device-width: 1366px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .mockup1 img {
    width: 31%;
  }

  .mockup2 {
    top: 40%;
    left: 23%;
    width: 70%;
  }

  .mockup2 img {
    width: 41%;
  }
}

@media only screen and (min-device-width: 700px) and (max-device-width: 1050px) {
  .partie2 {
    padding-top: 45%;
    z-index: 2;
  }
}
@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes focus-in-expand-fwd {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-800px);
    transform: translateZ(-800px);
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
@keyframes focus-in-expand-fwd {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-800px);
    transform: translateZ(-800px);
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
