.servicePartie1 {
  margin-top: 5%;
}

.service .partie3Titre {
  text-align: center;
  color: #f5a624;
}

.service .clientBloc {
  text-align: center;
}

.service .serveurBloc {
  text-align: center;
}

.service .restaurateurBloc {
  text-align: center;
}

.service .clientBloc img {
  width: 12%;
  margin-bottom: 6%;
}

.service .serveurBloc img {
  width: 24%;
  margin-bottom: 7%;
}

.service .restaurateurBloc img {
  width: 22%;
  margin-bottom: 5%;
}

.service .btnServices {
  background-color: #f5a624 !important;
  margin-top: 2%;
  font-size: 24px;
  margin-bottom: 27%;
}
