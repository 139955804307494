.titreWaiting {
  color: #f5a624;
  text-align: center;
  margin-top: 5%;
  font-size: 80px;
  margin-bottom: 5%;
}

.logoWaiting {
  text-align: center;
}
.logoWaiting img {
  width: 40%;
  margin-bottom: 10%;
}

@media (max-width: 640px) {
  /* CSS mobile ici */
  .logoWaiting img {
    width: 80%;
    margin-top: 10%;
  }

  .titreWaiting {
    margin-top: 30%;
    font-size: 40px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .logoWaiting img {
    width: 50%;
  }

  .titreWaiting {
    margin-top: 40%;
  }
}
