.navbar {
  background-color: #f5a624;
}


.navbar-expand {
  flex-flow: row nowrap;
  justify-content: space-evenly;
}
.navbar-light .navbar-nav .nav-link {
  color: #ffff;
}

.navbar-nav{
  text-align: center!important;
}
.typoFooter{
  font-size: 16px;
}

@media (max-width: 640px) {
  .navbar-nav{
    display: inline;
    text-align:'center'!important
  }
  .typoFooter{
    font-size: 16px;
  }
}
